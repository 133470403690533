import {
  Col,
  Row,
  Typography,
  Form,
  Input,
  DatePicker,
  Select,
  Divider,
  Button,
  notification,
  Space,
  Card,
  Table,
  Spin,
  QRCode,
} from 'antd';

import { useEffect, useState } from 'react';
import { CreateLeadDto, Lead, LeadSource, LeadType } from '../../types/lead.types';
import moment from 'moment';
import API from '../../services/api-service';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DEMAND_OPTIONS } from 'src/constants/lead';
import { removeAccentCharacters } from 'src/utils/helpers';

const { Title } = Typography;

export default function LeadEntryPage() {
  const navigate = useNavigate();
  const params = useParams();
  const leadId = useParams<{ id: string }>().id;
  const [loading, setLoading] = useState(false);
  const isUpdateLead = leadId != 'entry';
  const [form] = Form.useForm();
  const [lead, setLead] = useState<Lead>();
  const [productOptions, setProductOptions] = useState<any>([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState<any>([]);
  const [leadSources, setLeadSources] = useState<any>([]);
  const [leadTypes, setLeadTypes] = useState<any>([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState<any>([]);
  const [leadTypeOptions, setLeadTypeOptions] = useState<any>([]);
  const [demandOptions, setDemandOptions] = useState<any>([
    {
      value: 0,
      label: 'Không có nhu cầu',
    },
    {
      value: 1,
      label: 'Có nhu cầu',
    },
    {
      value: 2,
      label: 'Rác',
    },
  ]);

  const DEFAULT_FORM_VALUE = {
    contactDate: dayjs(),
    demand: 1,
  };

  async function getProducts() {
    try {
      const response = await API.get('/api/products');
      setProductOptions(
        response.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
          searchname: removeAccentCharacters(item.name).toLowerCase(),
          code: item.code,
        })) ?? [],
      );
    } catch (err: any) {
      notification.error({
        message: 'Lỗi',
        description: err.message,
      });
    }
  }
  async function getCategories() {
    try {
      const response = await API.get('/api/products/categories');
      setProductCategoryOptions(
        response.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        })) ?? [],
      );
    } catch (err: any) {
      notification.error({
        message: 'Lỗi',
        description: err.message,
      });
    }
  }

  async function getLead(leadId: number) {
    try {
      const response = await API.get<Lead>(`/api/leads/${leadId}`);
      const data = response.data;
      form.setFieldsValue({
        ...data,
        phone: data.phoneNumber,
        contactDate: dayjs(data.contactDate),
        lastFollow: data.lastFollow ? dayjs(data.lastFollow) : null,
        appointmentDate: data.appointmentDate ? dayjs(data.appointmentDate) : null,
        conversations: null,
      });
      setLead(data);
    } catch (error) {
      throw new Error('Không lấy được thông tin lead');
    } finally {
      setLoading(false);
    }
  }

  async function getAllLeadSources() {
    const response = await API.get<LeadSource[]>('/api/categories/sources');
    const data = response.data;
    setLeadSources(data);
    setLeadSourceOptions(data.map((item: LeadSource) => ({ label: item.name, value: item.id })));
  }
  async function getAllLeadTypes() {
    const response = await API.get<LeadType[]>('/api/categories/types');
    const data = response.data;
    setLeadTypes(data);
    setLeadTypeOptions(data.map((item: LeadType) => ({ label: item.name, value: item.id })));
  }

  async function onCreateLead(backToList = false) {
    setLoading(true);
    try {
      try {
        await form.validateFields();
      } catch (error) {
        throw new Error('Vui lòng kiểm tra lại các trường dữ liệu');
      }
      const data = form.getFieldsValue();

      const submitData: CreateLeadDto = {
        name: data.name,
        phoneNumber: data.phone ?? [],
        address: data.address,
        productCategory: data.productCategory,
        contactDate: data.contactDate || dayjs(),
        sourceId: data.sourceId,
        description: data.description,
        demand: data.demand,
        appointmentDate: data.appointmentDate,
        products: data.products,
      };
      if (data.conversations) {
        submitData.conversations = [
          {
            conversationId: 0,
            content: data.conversations,
          },
        ];
      }
      const response = await API.post('/api/leads', submitData);
      if (response.status === 200) {
        notification.success({
          message: 'Tạo lead thành công',
        });
        form.resetFields();
        form.setFieldsValue({
          ...DEFAULT_FORM_VALUE,
          contactDate: data.contactDate || dayjs(),
          sourceId: data.sourceId,
        });
      } else {
        throw response.data.message;
      }
      if (backToList) {
        navigate('/leads');
      }
    } catch (error: any) {
      notification.error({
        message: 'Có lỗi xảy ra vui lòng thử lại',
        description: error.message ?? error,
        placement: 'top',
      });
    } finally {
      setLoading(false);
    }
  }

  async function onUpdateLead() {
    setLoading(true);
    try {
      try {
        await form.validateFields();
      } catch (error) {
        throw new Error('Vui lòng kiểm tra lại các trường dữ liệu');
      }
      const data = form.getFieldsValue();

      const submitData: Lead = {
        leadId: lead!!.leadId,
        name: data.name,
        phoneNumber: data.phone ?? [],
        address: data.address,
        productCategory: data.productCategory,
        contactDate: data.contactDate || dayjs(),
        sourceId: data.sourceId,
        description: data.description,
        demand: data.demand,
        appointmentDate: data.appointmentDate,
        products: data.products,
      };
      if (data.conversations) {
        submitData.conversations = [
          {
            conversationId: 0,
            content: data.conversations,
          },
        ];
      }
      const response = await API.put(`/api/leads/${lead!!.leadId}`, submitData);
      if (response.status === 200) {
        notification.success({
          message: 'Cập nhật lead thành công',
        });
        getLead(Number(leadId));
      }
    } catch (error: any) {
      notification.error({
        message: error.message || 'Không thể cập nhật lead, vui lòng thử lại sau',
      });
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = () => {
    if (isUpdateLead) {
      onUpdateLead();
    } else {
      onCreateLead();
    }
  };
  const onSubmitAndRedirect = async () => {
    if (isUpdateLead) {
    } else {
      try {
        await onCreateLead(true);
      } catch (error: any) {
        notification.error({
          message: 'Có lỗi xảy ra vui lòng thử lại',
          description: error.message ?? error,
          placement: 'top',
        });
      }
    }
  };
  const onSyncOrder = async () => {
    try {
      if (!isUpdateLead) {
        return;
      }
      await API.post('/api/leads/check_order', {
        leadId: lead?.leadId,
      });
      notification.info({
        message: 'Hệ thống sẽ kiểm tra và cập nhật đơn hàng',
        placement: 'top',
      });
    } catch (error: any) {
      notification.error({
        message: 'Có lỗi xảy ra vui lòng thử lại',
        description: error.message ?? error,
        placement: 'top',
      });
    }
  };
  const onSearchProduct = (keyword: String, option: any) => {
    return removeAccentCharacters(option.props.code.toLowerCase())
      .replace(/ /g, '')
      .includes(removeAccentCharacters(keyword.replace(/ /g, '')).toLowerCase().trim());
  };

  useEffect(() => {
    getCategories();
    getProducts();
    getAllLeadSources();
    getAllLeadTypes();
    if (isUpdateLead) {
      getLead(Number(leadId));
    }
  }, []);
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
      md: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
      md: { span: 16 },
    },
  };
  return (
    <Spin spinning={loading}>
      <Form
        // layout="vertical"
        form={form}
        style={{ padding: 16, marginBottom: 20 }}
        scrollToFirstError={true}
        initialValues={DEFAULT_FORM_VALUE}
        size="large"
        labelAlign="left"
        {...formItemLayout}
      >
        <Row gutter={[30, 0]} justify={'center'} align={'middle'}>
          <Title level={2}>{isUpdateLead ? 'Cập nhật' : 'Tạo'} LEAD</Title>
        </Row>
        <Row gutter={[{ sm: 30, md: 72 }, 0]}>
          <Col md={24} sm={24} lg={24} span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Tên khách hàng"
                  rules={[{ required: true, message: 'Vui lòng nhập tên lead' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="contactDate"
                  label="Ngày nhận lead"
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="phone" label="Liên hệ">
                  <Form.List name="phone">
                    {(fields, { add, remove }) => (
                      <>
                        <div className="mb-[10px]">
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Space key={key} className="w-full align-middle mb-[12px]">
                              <Form.Item noStyle shouldUpdate={true}>
                                {({ getFieldValue }: any) => {
                                  const currentValue = getFieldValue('phone')[index];
                                  return <QRCode value={currentValue} />;
                                }}
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="mb-0"
                                name={[name]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Không được để trống',
                                  },
                                  {
                                    message: 'Không đúng định dạng. Eg: 0909123456,http://zaloapp.com/qr/...',
                                    pattern: new RegExp('[0-9]{10}|https?://zalo.*'),
                                  },
                                ]}
                              >
                                <>
                                  <Input pattern="[0-9]{,10}|https?://zalo.*" />
                                </>
                              </Form.Item>
                              <MinusCircleOutlined
                                style={{
                                  margin: 5,
                                  fontSize: 20,
                                  color: '#aaa',
                                }}
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </Space>
                          ))}
                        </div>

                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} className="flex items-center">
                            <PlusOutlined /> Thêm liên hệ
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item name="address" label="Địa chỉ">
                  <Input />
                </Form.Item>

                <Form.Item
                  name="sourceId"
                  label="Nguồn khách hàng"
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select options={leadSourceOptions} />
                </Form.Item>
                {/* <Form.Item
                  name="categoryId"
                  label="Nhóm khách hàng"
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select options={leadTypeOptions} />
                </Form.Item> */}
                <Form.Item name="demand" label="Nhu cầu">
                  <Select options={DEMAND_OPTIONS} />
                </Form.Item>
                <Form.Item name="productCategory" label="Nhóm sản phẩm">
                  <Select options={productCategoryOptions} mode="tags" showSearch={true} />
                </Form.Item>
                <Form.Item name="products" label="Sản phẩm quan tâm">
                  <Select options={productOptions} mode="tags" showSearch={true} filterOption={onSearchProduct} />
                </Form.Item>
                <Form.Item name="description" label="Ghi chú">
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="appointmentDate" label="Ngày hẹn follow">
                  <DatePicker format={'DD-MM-YYYY'} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col md={24} sm={24} lg={24} span={24}>
            <Form.Item
              name="conversations"
              label="Lịch sử trao đổi"
              // rules={[{ required: isUpdateLead ? false : true }]}
            >
              <Input.TextArea />
            </Form.Item>
            {isUpdateLead &&
              lead?.conversations?.map(conversation => (
                <Card
                  type={conversation.createdBy && conversation.createdBy != lead.saleId ? 'inner' : undefined}
                  title={`${dayjs(conversation.createdAt).format('HH:mm DD/MM/YYYY')} - ${conversation.createdUser?.fullName ?? ''}`}
                  className=" mb-4"
                >
                  {conversation.content}
                </Card>
              ))}
          </Col>
        </Row>
        <Divider />
        {isUpdateLead && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={[8, 8]} justify={'space-between'}>
                  <Typography.Title level={5}>Lịch sử đơn hàng</Typography.Title>
                  <Button danger type="primary" onClick={onSyncOrder}>
                    Đồng bộ đơn hàng
                  </Button>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={lead?.orders}
                  columns={[
                    {
                      title: 'Mã đơn hàng',
                      key: 'id',
                      dataIndex: 'orderInfo',
                      render: item => {
                        return (
                          <a
                            href={`https://serica.getflycrm.com/#/crm/order/detail/${item.getflyOrderId}`}
                            className="underline text-blue-500"
                          >
                            #{item.getflyOrderId}
                          </a>
                        );
                      },
                    },
                    {
                      title: 'SKU',
                      dataIndex: 'orderInfo',
                      render: item => {
                        return (
                          <>
                            {item.orderItems.map((item: any) => (
                              <p>{item.product.code}</p>
                            ))}
                          </>
                        );
                      },
                    },
                    {
                      title: 'Tên sản phẩm',
                      dataIndex: 'orderInfo',
                      render: item => {
                        return (
                          <>
                            {item.orderItems.map((item: any) => (
                              <p>{item.product.name}</p>
                            ))}
                          </>
                        );
                      },
                    },
                    {
                      title: 'Số lượng',
                      dataIndex: 'orderInfo',
                      render: item => {
                        return (
                          <>
                            {item.orderItems.map((item: any) => (
                              <p>{item.product.quantity}</p>
                            ))}
                          </>
                        );
                      },
                    },
                    {
                      title: 'Ngày mua hàng',
                      key: 'orderDate',
                      dataIndex: 'orderInfo',
                      render: item => dayjs(item.getflyOrderDate).format('DD/MM/YYYY'),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Row gutter={[16, 16]} className="mt-[32px]">
          <Col span={24}>
            <Row style={{ justifyContent: 'flex-end' }}>
              {isUpdateLead ? (
                <Button
                  type="primary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={onUpdateLead}
                >
                  {'Cập nhật'}
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 20,
                    }}
                    onClick={onSubmit}
                    htmlType="submit"
                  >
                    {'Lưu và tiếp tục'}
                  </Button>

                  <Button
                    type="default"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={onSubmitAndRedirect}
                    htmlType="submit"
                  >
                    {'Lưu và thoát'}
                  </Button>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
